var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shell-box"},[_c('el-breadcrumb',{staticClass:"breadcrumb",attrs:{"separator":"/"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/myHome' }}},[_vm._v("首页")]),_c('el-breadcrumb-item',{attrs:{"to":{
        path: `/routerPolicyManagement/nonCompanyPolicyManagement`,
      }}},[_vm._v("保单列表")]),_c('el-breadcrumb-item',[_vm._v("保单详情")])],1),_c('div',{staticClass:"form"},[_c('policyDetails',{attrs:{"detailData":_vm.policyDetail,"updateDetail":_vm.updateRecordDetail,"auditProcessList":_vm.auditProcessList,"permissions":_vm.policyDetailPermissions}})],1),_c('div',{staticClass:"operating-box"},[_c('el-button',{attrs:{"size":"mini"},on:{"click":_vm.handleBack}},[_vm._v("返回")]),(
        _vm.policyDetail.approvalStatus != 2 &&
        _vm.hasPerms(`M20-edit_${_vm.$route.meta.policyType}`)
      )?_c('el-button',{attrs:{"size":"mini","type":"primary","plain":""},on:{"click":_vm.handleEdit}},[_vm._v("编辑")]):_vm._e(),(
        _vm.policyDetail.approvalStatus == 2 &&
        _vm.hasPerms(`M20-recall_${_vm.$route.meta.policyType}`)
      )?_c('el-button',{attrs:{"size":"mini","type":"primary","plain":""},on:{"click":_vm.handleRevocation}},[_vm._v("撤回")]):_vm._e(),(
        _vm.$route.meta.policyType != 1 &&
        _vm.hasPerms(`M20-downloadDoc_${_vm.$route.meta.policyType}`)
      )?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":_vm.downloadAllAttachment}},[_vm._v("下载全部附件")]):_vm._e()],1),_c('DragMultipleUpload',{attrs:{"show":_vm.showUpload,"fileSzie":10,"dataEcho":_vm.dataEcho,"canEdit":false},on:{"update:show":function($event){_vm.showUpload=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }