<template>
  <div class="el-collapse-box">
    <AssociationScroll>
      <AssociationScrollView title="基本信息" name="0">
        <div style="padding: 18px 12px 12px">
          <el-descriptions
            class="margin-top"
            labelClassName="policy-label-style"
            contentClassName="policy-label-style"
          >
            <el-descriptions-item
              label="投保日期"
              v-if="permissions.insuranceOfDate"
            >
              <template slot="label"> 投保日期 </template>
              {{ detailData.insuranceOfDate }}
            </el-descriptions-item>
            <el-descriptions-item label="保单号" v-if="permissions.policyNo">
              {{ detailData.policyNo }}
              <img
                src="@/assets/images/policy/copyText.png"
                alt
                v-if="detailData.policyNo"
                @click="handleCopy(detailData.policyNo)"
                style="
                  width: 16px;
                  height: 16px;
                  margin-top: 2px;
                  margin-left: 10px;
                  cursor: pointer;
                "
                srcset
              />
            </el-descriptions-item>
            <el-descriptions-item label="起保时间" v-if="permissions.startDate">
              <template slot="label">
                <span style="color: rgba(201, 66, 66, 1)">*</span>
                起保时间
              </template>
              {{ detailData.startDate }}
            </el-descriptions-item>
            <el-descriptions-item v-if="permissions.endDate">
              <template slot="label">
                <span style="color: rgba(201, 66, 66, 1)">*</span>
                保险到期
              </template>
              {{ detailData.endDate }}
            </el-descriptions-item>
            <!-- <el-descriptions-item v-if="permissions.salesmanName">
              <template slot="label">
                <span style="color: rgba(201, 66, 66, 1)">*</span>
                业务员
              </template>
              {{ detailData.salesmanName }}
            </el-descriptions-item> -->
            <el-descriptions-item v-if="permissions.recorder">
              <template slot="label">
                <span style="color: rgba(201, 66, 66, 1)">*</span>
                录单员
              </template>
              {{ detailData.recorderName }}
            </el-descriptions-item>
            <!-- <el-descriptions-item label="所属省份"
              v-if="permissions.province">{{ detailData.province }}</el-descriptions-item>

            <el-descriptions-item label="所属地区"
              v-if="permissions.city">{{
              detailData.city
            }}</el-descriptions-item> -->
            <el-descriptions-item
              label="所属部门"
              v-if="permissions.salesmanDeptName"
              >{{ detailData.salesmanDeptName }}</el-descriptions-item
            >
            <el-descriptions-item
              label="含税保费"
              v-if="permissions.totalPremium"
              >{{
                detailData.premiumItax
                  ? Number(detailData.premiumItax).toFixed(2)
                  : "0.00"
              }}</el-descriptions-item
            >
            <el-descriptions-item
              label="不含税保费"
              v-if="permissions.premiumEtax"
              >{{
                detailData.premiumEtax
                  ? Number(detailData.premiumEtax).toFixed(2)
                  : "0.00"
              }}</el-descriptions-item
            >
            <el-descriptions-item
              label="税额"
              v-if="permissions.totalPremium"
              >{{
                detailData.taxAmount
                  ? Number(detailData.taxAmount).toFixed(2)
                  : "0.00"
              }}</el-descriptions-item
            >
            <el-descriptions-item
              label="创建时间"
              v-if="permissions.createTime"
              >{{ detailData.createTime }}</el-descriptions-item
            >
            <el-descriptions-item label="绑定客户" v-if="permissions.bindUser"
              >{{ detailData.bindUserName }}-{{
                detailData.bindUserPhone
              }}</el-descriptions-item
            >
          </el-descriptions>
        </div>
      </AssociationScrollView>
      <AssociationScrollView
        title="被保险人"
        name="1"
        v-if="permissions.policyInsuredList"
      >
        <div style="padding: 18px 12px 12px">
          <div
            v-for="(item, index) in detailData.policyInsuredList"
            :key="index"
          >
            <div class="personnel_repetition" v-if="item.check">
              <i
                class="el-icon-warning"
                style="
                  color: rgba(252, 184, 24, 1);
                  margin-right: 8px;
                  font-size: 24px;
                "
              ></i>
              <span>重复提示：此被保险人（{{ item.cardNo }}）已重复存在！</span>
              <span
                class="personnel_repetition_details"
                @click="lookRepetition(item, 1)"
                >查看详情</span
              >
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 24px;
              "
            >
              <el-radio-group disabled v-model="item.playersType">
                <el-radio :label="1">团体（企业、社会团体及其他组织)</el-radio>
                <el-radio :label="2">个人</el-radio>
              </el-radio-group>
            </div>
            <el-descriptions
              class="margin-top"
              labelClassName="policy-label-style"
              contentClassName="policy-label-style"
            >
              <el-descriptions-item label="被保险人">
                <template slot="label">
                  <span style="color: rgba(201, 66, 66, 1)">*</span>
                  被保险人
                </template>
                {{ item.playersName }}
              </el-descriptions-item>
              <el-descriptions-item label="证件类型">
                <template slot="label">
                  <span style="color: rgba(201, 66, 66, 1)">*</span>
                  证件类型
                </template>
                {{
                  item.playersType == 1 ? "统一社会信用代码" : item.cardTypeName
                }}
              </el-descriptions-item>
              <el-descriptions-item label="证件类型">
                <template slot="label">
                  <span style="color: rgba(201, 66, 66, 1)">*</span>
                  证件号码
                </template>
                {{ item.cardNo }}
              </el-descriptions-item>
              <!-- <el-descriptions-item label="所在区域">
                <template slot="label">
                  <span style="color: rgba(201, 66, 66, 1)">*</span>
                  所在区域
                </template>
                {{ item.province }}{{ item.city }}
              </el-descriptions-item> -->
                <el-descriptions-item
                  label="出生日期"
                  v-if="item.playersType == 2"
                >
                  <template slot="label">
                    <!-- <span style="color: rgba(201, 66, 66, 1)">*</span> -->
                    出生日期
                  </template>
                  {{ item.birthday }}
                </el-descriptions-item>
                <el-descriptions-item label="性别" v-if="item.playersType == 2">
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    性别
                  </template>
                  {{ item.sex ? "男" : "女" }}
                </el-descriptions-item>
                <el-descriptions-item label="详细地址">
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    详细地址
                  </template>
                  {{ item.detailAddress }}
                </el-descriptions-item>
                <el-descriptions-item label="联系人名称">
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    受益人姓名
                  </template>
                  {{ item.contactName }}
                </el-descriptions-item>
                <el-descriptions-item label="联系电话">
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    联系电话
                  </template>
                  {{ item.contactPhone }}
                </el-descriptions-item>
            </el-descriptions>
          </div>
        </div>
      </AssociationScrollView>
      <AssociationScrollView
        title="承保公司"
        name="4"
        v-if="permissions.acceptCompanyList"
      >
        <div style="padding: 18px 12px 12px">
          <div
            v-for="(item, index) in detailData.acceptCompanyList"
            :key="index"
          >
            <el-descriptions
              class="margin-top"
              labelClassName="policy-label-style"
              contentClassName="policy-label-style"
            >
              <el-descriptions-item label="保险公司">
                <template slot="label">
                  <span style="color: rgba(201, 66, 66, 1)">*</span>
                  保险公司
                </template>
                {{ item.companyName }}
              </el-descriptions-item>
              <el-descriptions-item label="分支机构">
                <template slot="label"
                  ><span style="color: rgba(201, 66, 66, 1)">*</span
                  >分支机构</template
                >
                {{ item.branch }}
              </el-descriptions-item>
              <el-descriptions-item label="承保比例">
                <template slot="label">承保比例</template>
                {{ item.acceptRate }}
              </el-descriptions-item>
            </el-descriptions>
            <div class="el-radio" style="display: block; margin-bottom: 18px">
              <span
                :class="{
                  'el-radio__input': true,
                  'is-checked': item.acceptPrimary,
                }"
              >
                <span class="el-radio__inner"></span>
              </span>
              <span class="el-radio__label">主承保公司</span>
            </div>
          </div>
        </div>
      </AssociationScrollView>
      <AssociationScrollView
        title="标的信息"
        name="5"
        v-if="permissions.policySubjectList && $route.meta.policyType != 3"
      >
        <div style="padding: 18px 12px 12px">
          <div
            style="display: block; margin-bottom: 18px"
            v-if="detailData.policySubjectList"
          >
            <div class="el-radio">
              <span
                :class="{
                  'el-radio__input': true,
                  'is-checked': detailData.isSingle,
                }"
              >
                <span class="el-radio__inner"></span>
              </span>
              <span class="el-radio__label">单一标的</span>
            </div>
            <div class="el-radio">
              <span
                :class="{
                  'el-radio__input': true,
                  'is-checked': !detailData.isSingle,
                }"
              >
                <span class="el-radio__inner"></span>
              </span>
              <span class="el-radio__label">多个标的</span>
            </div>
          </div>
          <div v-if="detailData.policySubjectList && detailData.isSingle">
            <div
              v-for="(item, index) in detailData.policySubjectList"
              class="list-item"
              :key="index"
            >
              <el-descriptions
                class="margin-top"
                labelClassName="policy-label-style"
                contentClassName="policy-label-style"
              >
                <el-descriptions-item
                  label="设备品牌"
                  v-if="permissions.brandName"
                >
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    设备品牌
                  </template>
                  {{ item.brandName }}
                </el-descriptions-item>
                <el-descriptions-item label="设备类型">
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    设备类型
                  </template>
                  {{ item.deviceTypeName }}
                </el-descriptions-item>
                <el-descriptions-item
                  label="设备型号"
                  v-if="permissions.equipmentType"
                >
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    设备型号
                  </template>
                  {{ item.equipmentType }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    车架号 (机身编号)
                  </template>
                  {{ item.frameNo }}
                </el-descriptions-item>
                <!-- <el-descriptions-item>
                  <template slot="label"> 车牌号 </template>
                  {{ item.plate }}
                </el-descriptions-item> -->
                <el-descriptions-item>
                  <template slot="label">
                    <span
                      style="color: rgba(201, 66, 66, 1)"
                      >*</span
                    >
                    新设备购置价
                  </template>
                  {{ item.purchasePrice }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    出厂日期
                  </template>
                  {{ item.productionDate }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    主险保额
                  </template>
                  {{ item.mainInsuranceAmount }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    <span
                      style="color: rgba(201, 66, 66, 1)"
                      >*</span
                    >
                    整备质量
                  </template>
                  {{ item.curbWeight }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">年折旧率</template>
                  {{ item.yearlyDepreciation }}
                </el-descriptions-item>
              </el-descriptions>
                <el-descriptions-item>
                  <template slot="label">生产编号</template>
                  {{ item.itemNo }}
                </el-descriptions-item>
            </div>
          </div>
          <div v-else>
            <el-table :data="detailData.policySubjectList" border>
              <el-table-column
                label="序号"
                type="index"
                width="50"
              ></el-table-column>
              <el-table-column
                label="设备品牌"
                prop="brandName"
                width="180"
              ></el-table-column>
              <el-table-column
                label="设备类型"
                prop="deviceTypeName"
                width="180"
              ></el-table-column>
              <el-table-column
                label="设备型号"
                prop="equipmentType"
                width="180"
              ></el-table-column>
              <el-table-column
                label="车架号 (机身编号) "
                prop="frameNo"
              ></el-table-column>
              <el-table-column
                label="车牌号"
                prop="plate"
                v-if="$route.meta.policyType !== 3"
              ></el-table-column>
              <!-- <el-table-column label="发动机编号"
                prop="engineNo"></el-table-column> -->
              <!-- <el-table-column label="生产编号"
                prop="itemNo"></el-table-column> -->
              <el-table-column
                label="新设备购置价"
                prop="purchasePrice"
              ></el-table-column>
              <el-table-column
                label="出厂日期"
                prop="productionDate"
              ></el-table-column>
              <el-table-column
                label="主险保额"
                prop="mainInsuranceAmount"
              ></el-table-column>
              <el-table-column
                :label="$route.meta.policyType === 2 ? '吨位(Kg)' : '整备质量'"
                prop="curbWeight"
              ></el-table-column>
              <el-table-column
                label="年折旧率"
                prop="yearlyDepreciation"
              ></el-table-column>
              <!-- <el-table-column label="经销商"
                prop="agency"></el-table-column> -->
              <el-table-column label="产地" prop="origin">
                <template slot-scope="{ row }">
                  {{ row.origin == 1 ? "国产" : "进口" }}
                </template>
              </el-table-column>
              <!-- <el-table-column label="所在位置"
                prop="address"></el-table-column>
              <el-table-column label="累计工作小时"
                prop="workTime"></el-table-column> -->
            </el-table>
          </div>
        </div>
      </AssociationScrollView>
      <AssociationScrollView
        title="保障计划"
        name="6"
      >
        <div style="padding: 18px 12px 12px">
          <div style="display: flex;margin-bottom: 16px">
            <div>
              承保方案：{{ detailData.schemeName }}
            </div>
            <div style="margin-left:40px">
              保险金额：{{ detailData.schemeAmount }}
            </div>
          </div>
          <el-table
            :data="detailData.policyPlanList"
            border
            v-if="permissions.policyPlanList"
            style="width: 1000px"
          >
            <el-table-column
              label="序号"
              type="index"
              width="50"
            ></el-table-column>
            <el-table-column prop="termConfigurationName">
              <template slot="header">
                <div>险种（条款）名称</div>
              </template>
            </el-table-column>
            <el-table-column prop="sumInsured" label="保额/限额">
              <template slot="header">
                <div>保额/限额</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="limitCompensation"
              label="每次赔偿限额"
              width="180"
            ></el-table-column>
            <el-table-column prop="rate" label="年费率">
              <template slot-scope="scope"
                >{{ scope.row.rate
                }}{{ scope.row.rateType ? "%" : "元" }}</template
              >
            </el-table-column>
            <el-table-column prop="premium" label="保费">
              <template slot="header">
                <!-- <span class="nmmdx">*</span> -->
                <div>保费(元)</div>
              </template>
            </el-table-column>
          </el-table>
          <div class="insurance-company" v-if="permissions.policyPlanList">
            <div>
              总保费：{{
                detailData.totalPremium
                  ? Number(detailData.totalPremium).toFixed(2)
                  : "0.00"
              }}元
            </div>
          </div>
          <div class="clause-title" v-if="permissions.extensionClause">
            限额设定
          </div>
          <el-input
            type="textarea"
            v-if="permissions.extensionClause"
            v-model="detailData.limitAgreement"
            :rows="6"
            disabled
          ></el-input>
          <div class="clause-title" v-if="permissions.specialAgreement">
            特别约定
          </div>
          <el-input
            type="textarea"
            v-if="permissions.specialAgreement"
            v-model="detailData.specialAgreement"
            :rows="6"
            disabled
          ></el-input>
        </div>
      </AssociationScrollView>
      <AssociationScrollView
        title="保单文件"
        name="7"
      >
        <FormListUpload
          required
          title="电子保单"
          :isEdit="false"
          ref="dzbd"
          :limitSize="10"
          attachmentType="dzbd"
          v-model="detailData.attachments.dzbd"
        ></FormListUpload>
      </AssociationScrollView>

      <AssociationScrollView title="审核流程" name="8">
        <policyAuditProcess :auditList="auditProcessList" />
      </AssociationScrollView>
      <template
        slot="tabs-down"
        v-if="updateDetail && permissions.policyPlanList"
      >
        <div class="steps-title">
          <div class="decoration"></div>
          <span>修改记录</span>
          <div class="decoration2"></div>
        </div>
        <div class="steps-box">
          <div class="step" v-for="(item, index) in updateDetail" :key="index">
            <div
              :class="{
                'step-left': true,
                'step-left2': index == updateDetail.length - 1,
              }"
            >
              <img
                src="@/assets/images/policy/newStep.png"
                v-if="index === 0"
                alt
                srcset
              />
              <img src="@/assets/images/policy/oldStep.png" v-else alt srcset />
            </div>
            <div class="step-right">
              <div class="time">{{ item.operatorTime }}</div>
              <div class="step-body">
                <span v-if="item.description"
                  >{{ item.operatorUser }} {{ item.description }}</span
                >
                <span v-else-if="!item.hasBoom">
                  {{ item.operatorUser }} 修改了 {{ item.fieldDesc }}
                  <span v-if="item.updateOld"
                    >，由{{ item.updateOld }} 改为 {{ item.updateNew }}</span
                  >
                </span>
                <span v-else>
                  {{ item.operatorUser }} 修改了 {{ item.fieldDesc }}，
                  <span
                    class="el-button polecy-button el-button--text el-button--small"
                    style="text-decoration: underline; padding: 0"
                    @click="handleShowUpdate(item)"
                    >点击此处</span
                  >
                  查看详情
                </span>
              </div>
              <div style="height: 20px"></div>
            </div>
          </div>
        </div>
      </template>
    </AssociationScroll>
    <!-- 修改信息弹窗 -->
    <UpdateRecord
      v-model="isUpdateRecord"
      :updateOld="updateOld"
      :updateNew="updateNew"
      :showType="updateType"
    />
    <div class>
      <el-image
        style="width: 0px; height: 0px"
        ref="img-box"
        :preview-src-list="srcList"
      ></el-image>
    </div>
    <repetitionHistory
      v-model="repetitionHistoryVisible"
      :formData="repetitionHistoryForm"
    />
  </div>
</template>

<script>
import AssociationScroll from "@/components/AssociationScroll/AssociationScroll.vue";
import AssociationScrollView from "@/components/AssociationScroll/AssociationScrollView.vue";
import UpdateRecord from "@/views/policyManagement/components/UpdateRecord.vue";
import FormListUpload from "@/components/FormListUpload/index.vue";
import repetitionHistory from "@/views/policyManagement/components/repetitionHistory";
import policyAuditProcess from "@/views/policyManagement/policyAudit/components/policyAuditProcess.vue";
export default {
  props: {
    detailData: {
      type: Object,
      default: () => {},
    },
    updateDetail: {
      type: [Array, String],
      default: "",
    },
    auditProcessList: {
      type: Array,
      default: () => [],
    },
    orderType: {
      type: [String, Number],
      default: 1, //  类型，1：询价单、2：保单、3：批单
    },
    permissions: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isUpdateRecord: false,
      updateNew: [],
      updateOld: [],
      updateType: "附件",
      srcList: [],
      repetitionHistoryForm: {
        cardNo: "",
        salesmanId: "",
        dataType: "",
        orderType: "",
      },
      repetitionHistoryVisible: false,
      loginIdentity: "",
    };
  },
  created() {
    this.loginIdentity = this.$store.state.userInfo.loginIdentity;
  },
  components: {
    AssociationScroll,
    AssociationScrollView,
    UpdateRecord,
    FormListUpload,
    repetitionHistory,
    policyAuditProcess,
  },
  methods: {
    handleClickAnchor(v) {
      this.$refs["anchor" + v.index].scrollIntoView();
    },
    handleCopy(v) {
      this.$copyText(v).then(
        (e) => {
          console.log("copy arguments e:", e);
          this.$message.success("复制成功!");
        },
        (e) => {
          console.log("copy arguments e:", e);
          this.$message.error("复制失败!");
        }
      );
    },
    onPreview() {
      // this.detailData
      if (this.detailData.ep.fileType == "pdf") {
        window.open(this.detailData.ep.url, "_blank");
      } else {
        this.$refs["img-box"].showViewer = true;
        this.srcList = [this.detailData.ep.url];
      }
    },
    onDownload() {
      if (this.detailData.ep.fileType == "pdf") {
        this.pdfDownlad(this.detailData.ep.url, this.detailData.ep.fileName);
      } else {
        this.downloadImage(this.detailData.ep.url, this.detailData.ep.fileName);
      }
    },
    downloadImage(url, downloadName) {
      const link = document.createElement("a");
      link.setAttribute("download", downloadName);
      const image = new Image();
      // 添加时间戳，防止浏览器缓存图片
      image.src = url + "?timestamp=" + new Date().getTime();
      // 设置 crossOrigin 属性，解决图片跨域报错
      image.setAttribute("crossOrigin", "Anonymous");
      image.onload = () => {
        link.href = this.getBase64Image(image);
        link.click();
      };
    },
    pdfDownlad(url, fileName) {
      let xhr = new XMLHttpRequest();
      xhr.open("get", url, true);
      xhr.setRequestHeader("Content-Type", `application/pdf`);
      xhr.responseType = "blob";
      let that = this;
      xhr.onload = function () {
        if (this.status == 200) {
          //接受二进制文件流
          var blob = this.response;
          that.downloadExportFile(blob, fileName);
        }
      };
      xhr.send();
    },
    downloadExportFile(blob, tagFileName) {
      let downloadElement = document.createElement("a");
      let href = blob;
      if (typeof blob == "string") {
        downloadElement.target = "_blank";
      } else {
        href = window.URL.createObjectURL(blob); //创建下载的链接
      }
      downloadElement.href = href;
      downloadElement.download = tagFileName;
      //下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      if (typeof blob != "string") {
        window.URL.revokeObjectURL(href); //释放掉blob对象
      }
    },
    getBase64Image(img) {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);
      var dataURL = canvas.toDataURL("image/png");
      return dataURL;
      // return dataURL.replace("data:image/png;base64,", "");
    },
    handleShowUpdate(e) {
      if (
        e.fieldDesc != "项目备注" &&
        e.fieldDesc != "未扩展不计免赔" &&
        e.fieldDesc != "扩展不计免赔2" &&
        e.fieldDesc != "扩展不计免赔1" &&
        e.fieldDesc != "特别约定" &&
        e.fieldDesc != "免费扩展条款" &&
        e.fieldDesc != "特别说明"
      ) {
        this.updateNew = e.updateNew ? JSON.parse(e.updateNew) : "";
        this.updateOld = e.updateOld ? JSON.parse(e.updateOld) : "";
      } else {
        this.updateNew = e.updateNew;
        this.updateOld = e.updateOld;
      }
      this.updateType = e.fieldDesc;
      this.isUpdateRecord = true;
    },
    lookRepetition(v, dataType) {
      this.repetitionHistoryForm = {
        cardNo: v.cardNo,
        salesmanId: this.detailData.salesmanId,
        dataType,
        orderType: 2,
        upstreamAgentId: v.upstreamAgentId,
      };
      this.repetitionHistoryVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-item {
  border-top: 1px dashed #999;
  padding-top: 15px;
  &:first-child {
    border-top: none;
    padding-top: 0;
  }
}
.personnel_repetition {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333333;
  padding: 8px 12px;
  margin-bottom: 24px;
  background: #cce6ff;
  border-radius: 8px;
  &_details {
    color: #0080ff;
    text-decoration: underline;
    cursor: pointer;
  }
}
.el-collapse-box {
  height: 100%;
  .view-title {
    flex: 1;
    display: flex;
    justify-content: space-between;
    &-left {
      display: flex;
      align-items: center;
      height: 48px;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      img {
        width: 6px;
        height: 24px;
        margin-right: 8px;
      }
    }
    &-right {
      display: flex;
      align-items: center;
    }
    &-float {
      position: fixed;
      background-color: #ffffff;
      border-bottom: none;
      border-radius: 8px 8px 0 0;
      padding: 12px 24px 0;
      margin: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
      z-index: 3;
    }
  }
  .el-collapse {
    border: none;
    .el-collapse-item {
      margin-bottom: 10px;
      padding: 24px;
      border-radius: 8px;
      border: none;
      background-color: #fff;
      overflow: hidden;
      ::v-deep .el-collapse-item__arrow {
        display: none;
      }
    }
  }
  .insurance-company {
    display: flex;
    justify-content: flex-end;
    max-width: 1000px;
    font-weight: bold;
    color: #4278c9;
    font-size: 16px;
    margin-top: 16px;
  }
  .clause-title {
    margin: 16px 0;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }
  ::v-deep .el-descriptions-item__cell {
    padding-bottom: 20px;
  }
  .steps-title {
    display: flex;
    align-items: center;
    margin-top: 30px;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    .decoration {
      height: 20px;
      width: 6px;
      border-radius: 3px;
      background-color: #4278c9;
      margin-right: 10px;
      margin-left: 14px;
    }
    .decoration2 {
      flex: 1;
      height: 1px;
      margin-left: 8px;
      margin-right: 20px;
      background-color: #cccccc;
    }
  }
  .steps-box {
    height: calc(100% - 50px);
    padding-top: 24px;
    overflow: auto;
    .step {
      display: flex;
      &-left {
        position: relative;
        width: 6px;
        background-color: #e1e1e1;
        margin-right: 10px;
        margin-left: 14px;
        img {
          position: absolute;
          top: -4px;
          left: 50%;
          transform: translateX(-50%);
          width: 16px;
          height: 16px;
        }
      }
      &-left2 {
        background-color: transparent;
      }
      &-right {
        flex: 1;
        .time {
          font-size: 12px;
          font-weight: 300;
          color: #333333;
          margin-bottom: 4px;
        }
        .step-body {
          font-size: 12px;
          color: #333333;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.policy-label-style {
  align-items: center !important;
  font-size: 14px;
  color: #333333;
}
.el-collapse-box {
  .el-table__row {
    .cell {
      color: #333;
      font-size: 14px;
    }
  }
  .el-table__header-wrapper {
    background-color: #f0f4fb;
    // overflow: hidden;
  }
  .has-gutter,
  .el-table__fixed-header-wrapper {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    background: #f0f4fb;
    tr {
      background-color: #f0f4fb;
      border-radius: 8px;
      overflow: hidden;
    }
    th {
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
  .el-table__body {
    tr {
      position: relative;
    }
    // .table-del {
    //   position: absolute;
    //   top: 50%;
    //   right: -50px;
    //   transform: translateY(-50%);
    //   font-size: 30px;
    //   color: #c94242;
    //   cursor: pointer;
    // }
    .el-form-item {
      margin: 0;
    }
  }
  .plan-table {
    .has-gutter,
    .el-table__fixed-header-wrapper {
      tr {
        overflow: hidden;
      }
    }
    .el-table__body-wrapper {
      overflow: visible;
    }
  }
  .ep-b {
    font-size: 14px;
    color: #0080ff;
    margin-left: 24px;
    cursor: pointer;
    border-bottom: 1px solid #0080ff;
  }
  .policy-ep-img {
    width: 20px;
    height: 20px;
    margin-right: 14px;
  }
}
</style>
